<div class="flex gap-2">
  <div class="form-field">
    <input
      type="text"
      [formControl]="control"
      [ngClass]="{ filled: control.value, applied: discountCode() }"
    />
    <label class="pointer-events-none">Discount Code</label>
    <fa-icon class="valid-mark" size="sm" [icon]="faCheckCircle" />
  </div>
  <button
    class="border px-4 rounded-lg bg-gray-100 apply-button"
    [disabled]="!control.value"
    [ngClass]="{ filled: control.value }"
    (click)="handleButtonClick()"
  >
    @if (discountCode()) {
      <div class="flex gap-1">
        <fa-icon [icon]="faTrash" [size]="'sm'" />
        <span>Remove</span>
      </div>
    } @else {
      <span>Apply</span>
    }
  </button>
</div>
