<div class="flex flex-col items-start gap-20 bg-neutral-100 p-24">
  <div class="flex flex-col w-1/2 gap-20">
    @for (question of faq.questions; track $index) {
      <app-accordion [accordion]="question"></app-accordion>
    }
  </div>
  <app-buttons-section
    [buttonsSection]="faq.buttonsSection"
  ></app-buttons-section>
</div>
