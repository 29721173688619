<div class="relative flex flex-col items-center justify-center">
  @if (header) {
    @if (header.url == null) {
      <img
        appImageUrl
        [iconData]="header.image.data"
        class="object-cover min-h-72"
        [ngStyle]="{ height: headerSize[header.size] + 'vh' }"
      />
    } @else {
      <a [href]="header.url">
        <img
          appImageUrl
          [iconData]="header.image.data"
          class="object-cover min-h-72"
          [ngStyle]="{ height: headerSize[header.size] + 'vh' }"
        />
      </a>
    }
    <div class="intro pointer-events-none">
      <div
        class="intro-text pointer-events-none"
        [ngStyle]="{ 'font-size': headerSize[header.size] + '%' }"
      >
        <markdown class="flex text-center intro-title">{{
          header.title
        }}</markdown>
        <markdown class="flex text-center intro-subtitle">{{
          header.description
        }}</markdown>
      </div>
      <app-buttons-section
        class="pointer-events-auto"
        [buttonsSection]="header.buttonsSection"
      ></app-buttons-section>
    </div>
  }
</div>
