<div class="flex flex-col items-start gap-4 cursor-pointer">
  <div
    class="flex flex-row items-center w-full justify-between"
    (click)="toggle()"
  >
    <div
      class="text-xl hover:font-bold"
      [ngClass]="expanded ? 'font-bold' : 'font-normal'"
    >
      {{ accordion.title }}
    </div>
  </div>
  @if (this.expanded) {
    <markdown>{{ accordion.text }}</markdown>
  }
</div>
