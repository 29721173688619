<div class="w-full flex justify-center">
  <div class="account-details-wrapper">
    <div class="flex flex-col gap-4 items-start w-full">
      @if (user$ | async; as user) {
        <div class="font-rockwell text-3xl font-semibold mt-3">
          Hello {{ user.firstName }}!
        </div>
      }
      @if (this.showDetails) {
        <div class="flex flex-col bg-stone-200 p-4 w-full rounded-md">
          <div class="flex flex-row justify-end">
            <div
              class="rounded-full hover:bg-stone-300 p-2"
              (click)="toggleDetails()"
            >
              <fa-icon [icon]="iconX" class="text-md"></fa-icon>
            </div>
          </div>
          <form [formGroup]="formGroup" (submit)="updateUser()">
            <div>
              <input
                class="mt-2 border rounded-md p-2 my-2 text-xl w-full focus:outline-none bg-gray-300"
                name="email"
                formControlName="email"
                readonly
              />
            </div>
            @if (showChangePassword) {
              <app-text-input
                [control]="formGroup.get('oldPassword')!"
                placeholder="Old password"
                type="password"
              ></app-text-input>
              <app-text-input
                [control]="formGroup.get('newPassword')!"
                placeholder="New password"
                type="password"
              ></app-text-input>
            }
            <button
              (click)="toggleChangePassword()"
              class="underline hover:text-red-500"
              type="button"
            >
              Enter new password
            </button>

            <app-text-input
              [control]="formGroup.get('firstName')!"
              placeholder="First name"
            ></app-text-input>
            <app-text-input
              [control]="formGroup.get('lastName')!"
              placeholder="Last name"
            ></app-text-input>
            <app-text-input
              [control]="formGroup.get('street')!"
              placeholder="Street"
            ></app-text-input>
            <app-text-input
              [control]="formGroup.get('postalCode')!"
              placeholder="Postal code"
            ></app-text-input>
            <app-text-input
              [control]="formGroup.get('city')!"
              placeholder="City"
            ></app-text-input>
            <div class="mt-6 flex justify-between">
              <button
                class="text-sm text-red-500 hover:text-red-600"
                (click)="deleteAccount()"
                type="button"
              >
                Delete account
                <fa-icon [icon]="faTrash"></fa-icon>
              </button>
              <button
                class="text-white font-bold py-3 text-lg rounded w-1/3"
                [ngClass]="
                  formGroup.valid
                    ? 'bg-red-600 hover:bg-red-700'
                    : 'bg-gray-400'
                "
                [disabled]="!formGroup.valid"
                type="submit"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      } @else {
        <div
          class="flex flex-row gap-2 items-center text-red-600 cursor-pointer"
          (click)="toggleDetails()"
        >
          <img
            appImageUrl
            [iconData]="accountDetails.editIcon.data"
            class="h-4"
          />
          <div class="font-semibold">{{ accountDetails.editLabel }}</div>
        </div>
      }
      <app-buttons-section
        [buttonsSection]="accountDetails.buttonsSection"
      ></app-buttons-section>
    </div>
  </div>
</div>
