<div
  class="my-8"
  [ngStyle]="
    this.productInfo.bgImage.data
      ? {
          'background-image':
            'url(' + toImageUrl(this.productInfo.bgImage.data) + ')',
          'background-size': 'cover',
          color: 'white',
        }
      : {}
  "
>
  <div class="product-info-wrapper">
    @if (!productInfo.imageOnRight) {
      <div class="relative flex justify-center items-center">
        <a [href]="productInfo.imageUrl" class="h-full">
          <img
            appImageUrl
            [iconData]="productInfo.image.data"
            class="object-cover h-full"
          />
        </a>
        <div class="absolute text-white">
          <app-button [buttonLink]="productInfo.videoButton"></app-button>
        </div>
      </div>
    }
    <div class="flex flex-col justify-center gap-4 p-8 grow object-fill">
      <markdown class="max-w-[500px] mt-4">{{ productInfo.text }}</markdown>
      <app-buttons-section
        [buttonsSection]="productInfo.buttonsSection"
      ></app-buttons-section>
    </div>
    @if (productInfo.imageOnRight) {
      <a [href]="productInfo.imageUrl" class="h-full">
        <img
          appImageUrl
          [iconData]="productInfo.image.data"
          class="object-cover h-full"
        />
      </a>
    }
  </div>
</div>
