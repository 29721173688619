<div class="basket-wrapper">
  <div class="basket">
    <div class="top-bg"></div>
    <ul class="progress-bar">
      <li class="previous">
        <b>1</b>
        <span> Personalisation </span>
      </li>
      <li class="active">
        <b>2</b>
        <span>Cart</span>
      </li>
      <li>
        <b>3</b>
        <span>Payment</span>
      </li>
    </ul>

    <div
      class="flex flex-row items-end sm:justify-between justify-center mt-4 w-full"
    >
      <div class="flex">
        <div class="font-rockwell font-semibold text-3xl">Your cart</div>
      </div>
      <div class="flex-col items-end gap-2 hidden sm:flex">
        <div class="text-lg">To pay: ${{ this.total }}</div>
        <button routerLink="/order" class="checkout-button">
          <span>Checkout</span>
          <fa-icon [icon]="faChevronRight" />
        </button>
      </div>
    </div>

    <div class="products">
      <table>
        <thead>
          <tr class="">
            <th class="text-start p-2 rounded-l-xl">Product</th>
            <th class="text-start p-2">Details</th>
            <th class="text-start p-2">Delivery</th>
            <th class="text-start p-2">Price</th>
            <th class="text-start p-2 rounded-r-xl">Total</th>
          </tr>
        </thead>
        <tbody>
          @for (item of basketItems; track $index) {
            <tr class="items-start">
              <td class="align-top">
                <div class="flex flex-col items-start p-2">
                  <div class="text-xl font-rockwell font-semibold">
                    {{ productType[item.type] }}
                  </div>
                  <div class="text-sm text-primary-red">{{ item.name }}</div>
                  <img
                    [src]="item.thumbnailUrl"
                    class="w-full max-w-32 rounded-md object-contain"
                  />
                </div>
              </td>
              <td class="align-top">
                <div class="flex flex-col items-start p-2 gap-2">
                  @for (control of item.controls; track $index) {
                    @if (control.controlType != controlType.Image) {
                      <div class="flex flex-row gap-2 text-xs">
                        <div
                          class="flex text-gray-500 font-semibold text-nowrap"
                        >
                          {{ control.name }}:
                        </div>
                        <div class="flex font-medium">
                          {{ control.valueDisplayName }}
                        </div>
                      </div>
                    }
                  }
                  <div class="flex flex-col justify-start gap-2 text-xs">
                    <div class="flex text-gray-500 font-semibold">Photos:</div>
                    <div class="flex flex-row gap-2">
                      @for (control of item.controls; track $index) {
                        @if (control.controlType == controlType.Image) {
                          <img [src]="control.value" class="h-12 rounded-md" />
                        }
                      }
                    </div>
                  </div>
                  <!-- VideoParams currently disabled
                  @if (item.type == productType.Video) {
                    <app-video-parameters-short
                      (onChange)="updateItems()"
                      [videoParams]="item.videoParams!"
                    ></app-video-parameters-short>
                  } -->
                </div>
              </td>

              <td colspan="3" class="align-top">
                <div class="flex justify-between">
                  <div class="p-2 pr-12">free</div>
                  <div class="p-2">{{ item.pricePreDiscount | currency }}</div>
                  <div class="flex pr-2">
                    <div class="flex flex-col items-start p-2">
                      <div>{{ item.price | currency }}</div>
                    </div>

                    <div
                      class="flex flex-row gap-2 items-center justify-end hover:underline cursor-pointer hover:text-primary-red-hover"
                      (click)="removeItem(item)"
                    >
                      <button>
                        <fa-icon [icon]="iconTrash" class="text-md"></fa-icon>
                      </button>
                    </div>
                  </div>
                </div>

                <app-code-input
                  [discountCode]="item.discountCode"
                  (onCodeApply)="applyDiscountCode(item, $event)"
                  (onCodeRemove)="removeDiscountCode(item)"
                />
              </td>
            </tr>
          }
        </tbody>
      </table>

      <div class="products-mobile">
        @for (item of basketItems; track $index) {
          <div class="flex flex-col items-start p-2 gap-2">
            <div class="text-xl font-rockwell font-semibold">
              {{ productType[item.type] }}
            </div>
            <div class="text-sm text-primary-red">{{ item.name }}</div>
            <div class="flex justify-between">
              <img
                [src]="item.thumbnailUrl"
                class="w-1/2 rounded-md object-contain"
              />
              <div class="flex flex-col items-end">
                <div class="text-xl">{{ item.price | currency }}</div>
                <div
                  class="flex flex-row gap-2 items-center justify-end hover:underline cursor-pointer text-primary-red hover:text-primary-red-hover"
                  (click)="removeItem(item)"
                >
                  <button>
                    <fa-icon [icon]="iconTrash" class="text-md"></fa-icon>
                  </button>
                  <div>Remove</div>
                </div>
              </div>
            </div>
            <app-code-input
              [discountCode]="item.discountCode"
              (onCodeApply)="applyDiscountCode(item, $event)"
              (onCodeRemove)="removeDiscountCode(item)"
            />
            @for (control of item.controls; track $index) {
              @if (control.controlType != controlType.Image) {
                <div class="flex flex-row gap-2 text-xs">
                  <div class="flex text-gray-500 font-semibold text-nowrap">
                    {{ control.name }}:
                  </div>
                  <div class="flex font-medium">
                    {{ control.valueDisplayName }}
                  </div>
                </div>
              }
            }
            <div class="flex flex-col justify-start gap-2 text-xs">
              <div class="flex text-gray-500 font-semibold">Photos:</div>
              <div class="flex flex-wrap flex-row gap-2">
                @for (control of item.controls; track $index) {
                  @if (control.controlType == controlType.Image) {
                    <img [src]="control.value" class="h-12 rounded-md" />
                  }
                }
              </div>
            </div>
            <!-- VideoParams currently disabled -->
            <!-- @if (item.type == productType.Video) {
              <app-video-parameters-short
                (onChange)="updateItems()"
                [videoParams]="item.videoParams!"
              ></app-video-parameters-short>
            } -->
          </div>
          <hr class="border-stone-200" />
        }
      </div>
    </div>
    <div class="flex flex-col bg-stone-100 rounded-md w-full p-8 gap-4">
      <div
        class="font-rockwell font-semibold text-2xl w-full text-left sm:text-end"
      >
        Summary
      </div>
      <div class="flex flex-col text-md items-end">
        <div class="flex flex-row justify-between gap-8 w-full sm:w-fit">
          <div>Pre discount:</div>
          <div>{{ this.preDiscountSum | currency }}</div>
        </div>
        <div
          class="flex flex-row justify-between gap-8 text-red-600 w-full sm:w-fit"
        >
          <div>Discount:</div>
          <div>-{{ this.discountSum | currency }}</div>
        </div>
      </div>
      <div class="font-semibold text-2xl w-full text-end">
        ${{ this.total }}
      </div>
      <div class="flex flex-row sm:justify-end">
        <button routerLink="/" class="continue-shopping-button">
          <span> Continue shopping </span>
          <fa-icon [icon]="faChevronRight" />
        </button>
      </div>
    </div>
    <div class="flex flex-row justify-between gap-8 w-full">
      <div class="fancy-boxes">
        @for (card of basketSummary.cards; track $index) {
          <app-fancybox-card
            [fancyboxCard]="card"
            [type]="fancyboxCardsType.medium"
          />
        }
      </div>
      <div class="flex flex-col items-center flex-grow">
        <button routerLink="/order" class="checkout-button">
          <span>Checkout</span>
          <fa-icon [icon]="faChevronRight" />
        </button>
      </div>
    </div>
  </div>
</div>
