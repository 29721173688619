import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MarkdownModule } from 'ngx-markdown';
import { AppConfigService } from '../../../config.service';
import { ButtonComponent } from "../../../core/button/button.component";
import { ButtonsSectionComponent } from "../../../core/buttons-section/buttons-section.component";
import { ProductInfo } from '../../../models/components/sections/productInfo.model';
import { ComponentEntry } from '../../../models/strapi/componentEnty.model';
import { ImageUrlDirective } from '../../../shared/directives/image-url.directive';
import { createImageUrl } from '../../../shared/utils/create-image-url';

@Component({
  standalone: true,
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.css'],
  imports: [MarkdownModule, CommonModule, ButtonComponent, ButtonsSectionComponent, ImageUrlDirective],
})
export class ProductInfoComponent {
  @Input() productInfo: ProductInfo;
  strapiUrl: string;

  constructor(
    private readonly configService: AppConfigService

  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }

  toImageUrl(iconData: ComponentEntry<any>) {
    return createImageUrl(iconData, this.strapiUrl);
  }
}
