import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MarkdownModule } from 'ngx-markdown';
import { AppConfigService } from '../../../config.service';
import { Accordion } from '../../../models/components/accordion.model';
import { ImageUrlDirective } from '../../../shared/directives/image-url.directive';
import { AccordionSecondaryComponent } from "../accordion-secondary/accordion-secondary.component";

@Component({
  standalone: true,
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css'],
  imports: [CommonModule, MarkdownModule, AccordionSecondaryComponent, ImageUrlDirective],
})
export class AccordionComponent {
  @Input() accordion: Accordion;
  expanded = false;
  strapiUrl: string;

  constructor(
    private readonly configService: AppConfigService

  ) {
    this.strapiUrl = configService.getConfigOrThrow().strapiUrl;
  }


  toggle() {
    this.expanded = !this.expanded;
  }
}
