<div class="order-item">
  <div class="relative flex">
    <img [src]="orderItem.product.thumbnail" class="thumbnail" />
    @if (orderItem.video) {
      <div class="absolute flex flex-col justify-end h-full p-2 text-sm">
        <div class="flex flex-row">
          <div
            class="flex text-white text-sm rounded-sm font-bold bg-green-600 px-1"
          >
            {{ qualityOption.details }}
          </div>
        </div>
      </div>
    }
  </div>
  <div class="flex flex-row justify-between p-4 w-full">
    <div class="flex flex-col justify-between gap-2">
      <div class="flex flex-col gap-1">
        <div class="text-2xl font-rockwell font-semibold">
          {{ orderItem.product.name }}
        </div>
        <div class="flex flex-col">
          <div class="text-primary-red">
            {{ orderItem.product.type }}
          </div>
          @if (orderItem.video) {
            <div>
              Valid till
              <span class="font-semibold">{{
                orderItem.video.validTo | date: "shortDate"
              }}</span>
            </div>
          }
          <div class="flex md:hidden">
            <ng-container [ngTemplateOutlet]="additionalInfo" />
          </div>
        </div>
      </div>
      @if (orderItem.video?.playerUrl) {
        <a
          [href]="orderItem.video!.playerUrl"
          target="_blank"
          class="text-primary-red text-2xl hover:text-primary-red-hover"
        >
          <fa-icon [icon]="faPlayCircle" class="text-md" /> Watch video</a
        >
      }
      @if (orderItem.video?.downloadUrl) {
        <a
          [href]="orderItem.video!.downloadUrl"
          target="_blank"
          class="text-primary-red text-2xl hover:text-primary-red-hover"
        >
          <fa-icon [icon]="faDownload" class="text-md" /> Download video</a
        >
      }
    </div>
    <div class="hidden md:flex">
      <ng-container [ngTemplateOutlet]="additionalInfo" />
    </div>
  </div>
</div>

<ng-template #additionalInfo>
  <div class="flex-col items-end gap-4 flex">
    @if (orderItem.video) {
      <div class="flex flex-col md:items-end">
        <div>
          Status:
          <span class="text-primary-red font-semibold">{{
            orderItem.video.status
          }}</span>
        </div>
        <!-- VideoParams currently disabled -->
        <!-- <div>
        Delivery {{ deliveryTimeOption.displayName }}:
        <span class="text-green-500 font-semibold">
          up to {{ deliveryTimeOption.details }}</span>
      </div> -->
      </div>
    }
  </div>
</ng-template>
