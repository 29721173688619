<div class="flex flex-col items-start gap-8">
  <div
    class="flex flex-row items-center w-full justify-between cursor-pointer"
    (click)="toggle()"
  >
    <div class="text-4xl font-rockwell font-bold">{{ accordion.title }}</div>
    @if (this.expanded) {
      <img appImageUrl [iconData]="accordion.iconExpanded.data" />
    } @else {
      <img appImageUrl [iconData]="accordion.icon.data" />
    }
  </div>
  @if (this.expanded) {
    <markdown>{{ accordion.text }}</markdown>
    <div class="flex flex-col gap-2">
      @for (question of accordion.questions; track $index) {
        <app-accordion-secondary
          [accordion]="question"
        ></app-accordion-secondary>
      }
    </div>
  }
</div>
